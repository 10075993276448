<template>
  <div id="top">
    <v-app id="home"> 
      <navbar :pageIn="2"/>
      <v-btn class="float" rounded icon @click="$vuetify.goTo('#top')">
        <v-icon> mdi-arrow-up </v-icon>
      </v-btn>  
      <v-main>

        <v-carousel v-model="model" height="auto">
          <v-carousel-item
            v-for="(index,i) in datosSalones" :key="i"
          >
            <v-card
                  class="my-15 mx-10 pb-5"
                  elevation="10"

                >
                  <v-img
                    class="white--text align-end"
                    height="70px"
                    src="@/assets/media/logos/market-logo.jpg"
                  >
                    <v-card-title class="card-title-text">{{index.nombreEstablecimiento}}</v-card-title>
                  </v-img>

                  <v-row justify="center" class="my-10  px-10">
                    <v-col cols="12" md="4">
                      <v-row>
                          <p>{{index.descripcion}}</p>
                      </v-row>
                      <v-row class="my-1">
                        <iframe
                          :src="index.googleMaps"
                          style="border: 0"
                          allowfullscreen=""
                          loading="lazy"
                          class="google-map"
                        > </iframe> 
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="1"></v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12">
                          <Gallery v-bind:images="index.imagenes" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-chip-group class="ml-1 mt-0">
                          <v-btn icon @click="goToLink(index.redSocialFacebook)"> <v-icon> mdi-facebook</v-icon> </v-btn>
                          <v-btn icon @click="goToLink(index.redSocialIstagram)"> <v-icon> mdi-instagram</v-icon> </v-btn>
                          <v-btn icon @click="goToLink(index.redSocialWhatsapp)"> <v-icon> mdi-whatsapp</v-icon> </v-btn>
                        </v-chip-group>
                        <v-chip-group class="ml-10 mt-0">
                          <v-chip
                            v-for="(inx,p) in index.servicios"
                            :key="p"
                          >
                            {{ inx }}
                          </v-chip>
                        </v-chip-group>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="my-1"></v-row>
                </v-card>
          </v-carousel-item>
        </v-carousel>


        
        
        
        <v-row class=" mt-2 py-15 px-15">
          <v-col cols="12" md="6">
            <div class="mt-15"></div>

            <h1 class="first-line">Quieres aparecer aqui?</h1>
            <h2 class="second-line">Contactanos</h2>
            LLena este formulario o comunicate a través de nuestras redes sociales, WhatsApp y vía telefónica al 228 307 8010
            
          </v-col>
          <v-col cols="12" md="6">
            <template>
              <form>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="name"
                      label="Nombre del Establecimiento*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="direccion"
                      label="Embed de tu ubicacion en google maps*"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      outlined
                      v-model="descripcion"
                      label="Breve descripcion"
                    ></v-textarea>

                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="email"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      v-model="selectRedes"
                      :items="itemsRedes"
                      label="Red Social de Preferencia*"
                      required
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="redSocialFacebook"
                      label="Link Facebook"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="redSocialIstagram"
                      label="Link Instagram"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="redSocialWhatsapp"
                      label="Numero Whatsapp"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      outlined
                      chips
                      v-model="serviciosSelect"
                      :items="itemServicios"
                      label="Servicios"
                      multiple
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <vue-dropzone
                      ref="imgDropZone"
                      id="customdropzone"
                      :options="dropzoneOptions"
                      @vdropzone-complete="afterComplete"
                      @vdropzone-queue-complete="callThis"
                    ></vue-dropzone>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox
                      v-model="checkboxContact"
                      label="Estoy de acuerdo en que utilicen esta informacion para contactar conmigo en un plazo maximo de 5 dias habiles"
                      required
                    ></v-checkbox>

                  </v-col>
                </v-row>
                <v-btn @click="contactar()" outlined color="green">
                  Enviar Solicitud
                </v-btn>
              </form>
            </template>

          </v-col>
        </v-row>


        <FooterOne />       
      </v-main>
    </v-app>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js"></script>



<script>
import { COLORS } from "@/assets/theme/colors.js";
import navbar from "../components/navbar/navbar.vue";
import FooterOne from "../components/footer/footer.vue";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import Gallery from "../components/home/galeria/galeria.vue";
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import swal from "prettyalert";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, query, where, getDocs, getFirestore, doc, addDoc, deleteDoc   } from "firebase/firestore";
let uuid = require("uuid");


export default {
    name: "Marketplace",
  components: {
    navbar,
    FooterOne,
    VueSlickCarousel,
    vueDropzone: vue2Dropzone,
    Gallery
  },
  data(){
    return{
      checkboxContact: false,
      redSocialFacebook: "",
      redSocialIstagram: "",
      redSocialWhatsapp: "",
      itemsRedes:[ "Facebook", "Instagram", "Whatsapp"],
      selectRedes: "",

      name: "",
      direccion: "",
      email: "",

      tags: [
        'Work',
        'Home Improvement',
        'Vacation',
        'Food',
        'Drawers',
        'Shopping',
        'Art',
        'Tech',
        'Creative Writing',
      ],

      itemServicios: [
        {text: "Alberca", value: "Alberca"},
        {text: "Show de payasos", value: "Show de payasos"},
        {text: "Magia", value: "Magia"},
        {text: "Pinta caritas", value: "Pinta caritas"},
        {text: "Botargas", value: "Botargas"},
        {text: "Inflables", value: "Inflables"},
        {text: "Dj", value: "Dj"},
        {text: "Taquiza", value: "Taquiza"},
        {text: "Pastel", value: "MagPastelia"},
        {text: "Mesa de Dulces", value: "Mesa de Dulces"},
        {text: "Refrescos o Aguas", value: "Refrescos o Aguas"},
        {text: "Mantelería", value: "Mantelería"},
        {text: "Globos", value: "Globos"},
        {text: "Piñatas", value: "Piñatas"},
        {text: "Centros De Mesa", value: "Centros De Mesa"},
        {text: "Meseros", value: "Meseros"},
        {text: "Hostess", value: "Hostess"},
        {text: "Valet Parking", value: "Valet Parking"},
      ],

      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastra la imagen o haz clic aquí </p>
          <p class="form-text">Extensiones aceptadas: .jpg, .jpeg, .png</p>
          `,
        autoProcessQueue: false,
        maxFiles: 4
      },
      images: [],
      serviciosSelect: [],


      datosSalones: [],
      descripcion: ""

    }
  },
  mounted(){
    this.getSalones();

  },
  methods:{
    gotTo(){
      window.open("https://www.w3schools.com");
    },
    async contactar(){
      await this.$refs.imgDropZone.processQueue();
    },

    async getSalones(){
      const db = getFirestore();
      const q = query(collection(db, "marketplace"), where("aprovado", "==", true ));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let tempData = doc.data();
        this.datosSalones.push(tempData);
      });
      console.log(this.datosSalones);
    },

    clearForm(){
      this.name = "";
      this.direccion = "";
      this.email = "";
      this.selectRedes = "";
      this.redSocialFacebook = "";
      this.redSocialIstagram = "";
      this.redSocialWhatsapp = "";
      this.descripcion = "",
      this.serviciosSelect = [];
      this.images = [];
    },

    async subirSolicitud(){     
      const db = getFirestore();

      await addDoc(collection(db, "marketplace"), {
        nombreEstablecimiento: this.name,
        googleMaps: this.direccion,
        mail: this.email,
        redPreferida: this.selectRedes,
        redSocialFacebook: this.redSocialFacebook,
        redSocialIstagram: this.redSocialIstagram,
        redSocialWhatsapp: this.redSocialWhatsapp,
        servicios: this.serviciosSelect,
        imagenes: this.images,
        descripcion: this.descripcion,
        aprovado: false,
      });

      this.clearForm();
      swal("Solicitud enviada", "un socio se encargara de revisar tu solicitud, con suerte estaras en nuestro sitio muy pronto", "success");
      
    },

    async afterComplete(upload) {
      var imageName = uuid.v1();
      this.isLoading = true;
      try {
        //save image
        let file = upload;
        var metadata = {
          contentType: "image/png"
        };


        const storage = getStorage();
        const storageRef = ref(storage, `MediaSolicitudes/${imageName}.png`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error);
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.images.push({ src: downloadURL });
          });
        }
      );
      } catch (error) {
        console.log(error);
      }
      this.$refs.imgDropZone.removeFile(upload);
    },

    async callThis(){
      setTimeout(() => {this.subirSolicitud();}, 2000);
    },

    goToLink(sName) {
      if(sName != "")
        window.open(sName, "_blank");
    },
    
  }
    
}
</script>



<style scoped>

.image-div {
  display: flex;
  margin: 25px;
}
.image {
  max-width: 250px;
  margin: 15px;
}

.first-line{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 800;
  font-size: 3em;
}

.second-line{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 600;
  font-size: 2em;

}

.footer-text{
  font-family: 'Montserrat Alternates', sans-serif!important;
  font-size: 12px!important;
}

.footer-title{
  font-family: 'Poppins', sans-serif!important;
}


.descritive-text {
  color: #081c15;
  font-family: "Poppins", sans-serif !important;
}
.location-text {
  font-size: max(15px) !important;
}
.google-map {
  width: 100% !important;
  height: 450px !important;
}

.float {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 20px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: +1;
}

.my-float {
  margin-top: 22px;
}

.google-map {
  width: 100% !important;
  height: 200px !important;
}

@media only screen and (min-width: 600px) {
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #0c9;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: +1;
  }

  .my-float {
    margin-top: 22px;
  }
  .centered{
    margin-left: auto;
    margin-right: auto;
  }
  .card-title-text{
    font-family: 'Kanit', sans-serif;
    font-size: 1.4rem;
  }
}
.display-images{
  object-fit: cover;
}
</style>